.App {
    display: flex;
    width: 100%;
    align-items: stretch;
}

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

body {
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
}


a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #dae343;
  color: #000;
  margin-left: -250px;
  transition: all 0.5s;
}

.sidebar.is-open {
  margin-left: 0;
  transition: .5s;
}


.sidebar-header {
  background: #b9c02e;
}

.sidebar-header h3{
  color: #000;
  padding: 1em;
}

a.nav-link {  
  color: #000;
}

a.nav-link:hover {  
  color: #dae343;
}
.sidebar ul p {
    color: #000;
    padding: 10px;
}

.menu-open {
  background: #dae343;
}

.nav-item:hover {
    color: #b9c02e;
    background: #000;
}

.items-menu {
  color: #000;
  background: #dae343;
}

li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 50%;
    top: 10%
}

.sidebar-header > span {
    position: relative;
    float: right;
    margin: .5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
}
.side-menu {
  height: calc(100vh + 100px);
  overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
  width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: #dae343; 
  border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background: #b9c02e; 
}


/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

.content {
  padding: 20px;
  margin-left: 0;
  height: 100vh;
}

@media only screen and (max-width: 500px) {
    body {
      overflow:  hidden;
    }

    .content.is-open {
      margin-left: 100%;
    }

    .sidebar.is-open {
      min-width: 100%;
      max-width: 100%;
      margin-left: 0;
      transition: all 0.5s, height 0s;
    }

    .sidebar.is-open > .sidebar-header span{
      display: unset;
    }

    li a.dropdown-toggle::after {
        display: inline-block;
        position: relative;
        left: 68%;
    }   
}
