td, th { width: 100px; height: 35px; }

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    justify-content: center;
}

.partnersInfo {
    display: flex;
    position: relative;
    width: 30%;
    height: 70%;  
    flex-direction: row;
    align-items: center;
    justify-content: center;
    }

.partner {
    display: flex;
    justify-content: center;
}


.button_transp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: fit-content;
    background: #FFFFFF;
    box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    border-color: transparent;
    color: black;    
}

.group_buttons{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.spinner {
    display: flex;
    justify-content: center;
}