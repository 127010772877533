.sign-in {
    margin-top: 90px;
    max-width: 65%;
    padding-left: 35%;
  }
  
  .sign-in__heading {
    margin-bottom: 36px;
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    color: black;
  }
  
  .sign-in__button {
    margin-bottom: 10px;
    border: 1px solid #555;
    width: 100%;
    height: 48px;
    font-family: inherit;
    font-size: rem(18px);
    line-height: 48px;
    color: #999; 
    
  }

  .sign-in__button_red {
    margin-bottom: 10px;
    border: 1px solid #555;
    width: 100%;
    height: 48px;
    font-family: inherit;
    font-size: rem(18px);
    line-height: 48px;
    background-color: rgba(172, 20, 20, 0.603);     
  }
  