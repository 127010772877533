@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
.App {
    display: flex;
    width: 100%;
    align-items: stretch;
}

body {
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
}


a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #7386D5;
  color: #fff;
  margin-left: -250px;
  transition: all 0.5s;
}

.sidebar.is-open {
  margin-left: 0;
  transition: .5s;
}


.sidebar-header {
  background: #6d7fcc;
}

.sidebar-header h3{
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
    color: #fff;
    padding: 10px;
}

.sidebar a {
  color: #fff;
  padding: 8px;
}

.menu-open {
  background: #6d7fcc;
}

.nav-item:hover {
    color: #7386D5;
    background: #fff;
}

.items-menu {
  color: #fff;
  background: #6d7fcc;
}

li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 50%;
    top: 10%
}

.sidebar-header > span {
    position: relative;
    float: right;
    margin: .5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
}
.side-menu {
  height: calc(100vh - 130px);
  overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
  width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: #5466B3; 
  border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background: #3A4C99; 
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  padding: 20px;
  margin-left: 0;
  height: 100vh;
}

@media only screen and (max-width: 500px) {
    body {
      overflow:  hidden;
    }

    .content.is-open {
      margin-left: 100%;
    }

    .sidebar.is-open {
      min-width: 100%;
      max-width: 100%;
      margin-left: 0;
      transition: all 0.5s, height 0s;
    }

    .sidebar.is-open > .sidebar-header span{
      display: unset;
    }

    li a.dropdown-toggle::after {
        display: inline-block;
        position: relative;
        left: 68%;
    }
}

td, th { width: 100px; height: 35px; }

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    justify-content: center;
}

.map {
    display: flex;

}

.map_skooter {
    display: flex;
    position: relative;
    width: 100%;
    height: 50vh;  
    flex-direction: row;
    align-items: flex-start;       
}

.mapContainer {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;  
    flex-direction: row;
    align-items: flex-start;
    }

.skootersInfo {
    display: flex;
    position: relative;
    width: 30%;
    height: 70%;  
    flex-direction: row;
    align-items: center;
    justify-content: center;
    }

.skooter {
    display: flex;
    justify-content: center;
}

.mapboxgl-canvas-container{
    width: 100% !important;
    height: 70vh !important;
}    

.button_act {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.button_des {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.button_transparent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 10%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: #FFFFFF;
    box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    border-color: transparent;
    color: black;    
}

.group_buttons{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.spinner {
    display: flex;
    justify-content: center;
}
td, th { width: 100px; height: 35px; }

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    justify-content: center;
}

.buscar {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    justify-content: left;    
}

.spinner {
    display: flex;
    justify-content: center;
}
td, th { width: 100px; height: 35px; }

.buscar {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    justify-content: left;    
}
td, th { width: 100px; height: 35px; }

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    justify-content: center;
}

.partnersInfo {
    display: flex;
    position: relative;
    width: 30%;
    height: 70%;  
    flex-direction: row;
    align-items: center;
    justify-content: center;
    }

.partner {
    display: flex;
    justify-content: center;
}


.button_transp {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: #FFFFFF;
    box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    border-color: transparent;
    color: black;    
}

.group_buttons{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.spinner {
    display: flex;
    justify-content: center;
}
.sign-in {
    margin-top: 90px;
    max-width: 65%;
    padding-left: 35%;
  }
  
  .sign-in__heading {
    margin-bottom: 36px;
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    color: black;
  }
  
  .sign-in__button {
    margin-bottom: 10px;
    border: 1px solid #555;
    width: 100%;
    height: 48px;
    font-family: inherit;
    font-size: rem(18px);
    line-height: 48px;
    color: #999; 
    
  }

  .sign-in__button_red {
    margin-bottom: 10px;
    border: 1px solid #555;
    width: 100%;
    height: 48px;
    font-family: inherit;
    font-size: rem(18px);
    line-height: 48px;
    background-color: rgba(172, 20, 20, 0.603);     
  }
  
.App {
    display: flex;
    width: 100%;
    align-items: stretch;
}

body {
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
}


a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #dae343;
  color: #000;
  margin-left: -250px;
  transition: all 0.5s;
}

.sidebar.is-open {
  margin-left: 0;
  transition: .5s;
}


.sidebar-header {
  background: #b9c02e;
}

.sidebar-header h3{
  color: #000;
  padding: 1em;
}

a.nav-link {  
  color: #000;
}

a.nav-link:hover {  
  color: #dae343;
}
.sidebar ul p {
    color: #000;
    padding: 10px;
}

.menu-open {
  background: #dae343;
}

.nav-item:hover {
    color: #b9c02e;
    background: #000;
}

.items-menu {
  color: #000;
  background: #dae343;
}

li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 50%;
    top: 10%
}

.sidebar-header > span {
    position: relative;
    float: right;
    margin: .5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
}
.side-menu {
  height: calc(100vh + 100px);
  overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
  width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: #dae343; 
  border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background: #b9c02e; 
}


/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

.content {
  padding: 20px;
  margin-left: 0;
  height: 100vh;
}

@media only screen and (max-width: 500px) {
    body {
      overflow:  hidden;
    }

    .content.is-open {
      margin-left: 100%;
    }

    .sidebar.is-open {
      min-width: 100%;
      max-width: 100%;
      margin-left: 0;
      transition: all 0.5s, height 0s;
    }

    .sidebar.is-open > .sidebar-header span{
      display: unset;
    }

    li a.dropdown-toggle::after {
        display: inline-block;
        position: relative;
        left: 68%;
    }   
}

