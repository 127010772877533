td, th { width: 100px; height: 35px; }

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    justify-content: center;
}

.map {
    display: flex;

}

.map_skooter {
    display: flex;
    position: relative;
    width: 100%;
    height: 50vh;  
    flex-direction: row;
    align-items: flex-start;       
}

.mapContainer {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;  
    flex-direction: row;
    align-items: flex-start;
    }

.skootersInfo {
    display: flex;
    position: relative;
    width: 30%;
    height: 70%;  
    flex-direction: row;
    align-items: center;
    justify-content: center;
    }

.skooter {
    display: flex;
    justify-content: center;
}

.mapboxgl-canvas-container{
    width: 100% !important;
    height: 70vh !important;
}    

.button_act {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.button_des {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.button_transparent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 10%;
    width: fit-content;
    background: #FFFFFF;
    box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    border-color: transparent;
    color: black;    
}

.group_buttons{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.spinner {
    display: flex;
    justify-content: center;
}